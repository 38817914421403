.buttons-form {
  margin-top: 20px;
}

.register {
  font-family: Poppins, sans-serif;
}

.register-button {
  font-family: "Poppins", sans-serif;
}
