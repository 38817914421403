@media only screen and (min-width: 1101px) {
        .menu{
           display: none !important;
        }
        .container-botones{
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: space-between;
        }
        .log{
            display: inline-block;
            
        }
        .appbar{
          height: auto;
          width: auto;
        }
        .logo-largo{
          display: none !important;
        }
    }
    @media only screen and (max-width: 1100px) and (max-width: 767px){
        .container-botones{
            display: none !important;
        }
        .appbar{
          height: auto;
        }
        .logo{
          display: none !important;
        }
       
    }
    @media only screen and (min-width: 767px){
      .menu{
        display: none !important;
     }
      .container-botones{
        height: auto;
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: space-between;
    }
      .appbar{
        height: auto;
      }
      .logo-largo{
        display: none !important;
      }
     
  }

    /* Teléfonos */
@media only screen and (max-width: 575px){
  .logo{
    display: none !important;
  }
  .appbar{
    height: auto;
  }
  .box{
    width: 100vw;
  }
  .logo-largo{
    position: relative;
    max-width: 70vw;
    left: 3rem;
  }

}
@media only screen and (max-width: 465px){
  .logo{
    display: none !important;
  }
  .appbar{
    height: auto;
  }
  .box{
    width: 100vw;
  }
  .logo-largo{
    position: relative;
    left: 1rem;
    max-width: 15.625rem;
    height: 6.25rem;
  }
}
@media only screen and (max-width: 370px){
  .logo{
    display: none !important;
  }
  .appbar{
    height: auto;
  }
  .box{
    width: 100vw;
  }
  .logo-largo{
    position: relative;
    left: 0.125rem;
    max-width: 15.625rem;
    height: 6.25rem;
  }
}
@media only screen and (max-width: 350px){
  .logo{
    display: none !important;
  }
  .appbar{
    height: auto;
  }
  .box{
    width: 100vw;
  }
  .logo-largo{
    position: relative;
    left: 0.033rem;
    max-width: 15.625rem;
    height: 6.25rem;
  }
}
@media only screen and (max-width: 325px){
  .logo{
    display: none !important;
  }
  .appbar{
    height: auto;
  }
  .box{
    width: 100vw;
  }
  .logo-largo{
    right: 1rem;
    position: relative;
    max-width: 12.5rem;
  }
}