#file{
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    min-height: 65vh; 
    padding-top: 40px;
    padding-bottom: 10px;
    margin: auto;
}

@media only screen and (max-width: 450px) {
    #file {
    max-width: 300px;
    }
  }
  @media only screen and (max-width: 311px) {
    #file {
    max-width: 250px;
    }
  }