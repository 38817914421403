.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 2.5rem;
  right: 1.563rem;
  z-index: 20;
}
.icon-style {
  background-color: rgba(15, 65, 162, 1);
  border: 0.125rem solid #fff;
  border-radius: 50%;
  height: 3.125rem;
  width: 3.125rem;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}
.icon-style:hover {
  animation: none;
  background: #fff;
  color: rgb(49, 120, 200);
  border: 0.125rem solid rgba(15, 65, 162, 1);
}
@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
