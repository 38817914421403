.success {
  color: rgb(49, 206, 1);
}

.boton-register {
  width: 140px;
  height: 45px;
  font-family: Poppins;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  background-color: rgba(15, 65, 162, 1);
  color: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.boton-register:hover {
  background-color: rgb(49, 120, 200);
  box-shadow: 0px 15px 20px rgb(49, 120, 200);
  color: #fff;
  transform: translateY(-7px);
}
