.form-changePsw{
    max-width:400px; 
    margin:auto;
     min-height:40vh;
      margin-top:50px;
      /* background-color: rgb(233, 238, 244);
    border-radius: 0.313rem;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.404), 0 6px 20px 0 rgba(0, 0, 0, 0.382); */
    padding: 0.938rem;
 }
 
 @media only screen and (max-width: 314px) {
     .button-psw {
      font-size: 10px;
      padding: 8px 16px;
     }
   }