.form-resetPsw{
   max-width:400px; 
   margin:auto;
    min-height:40vh;
     margin-top:50px;
}

@media only screen and (max-width: 450px) {
    .form-resetPsw {
        margin-right: 50px;
        margin-left: 50px;
    }
  }